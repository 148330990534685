import Head from 'next/head';
import Link from 'next/link';

function Site() {
  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        <title>We create applications and web stuff forCandies</title>
      </Head>
      <div className="Header">
        <div className="Container">
          <Link href="/" passHref>
            <a className="Header__brand">forCandies</a>
          </Link>
          <h1>
            We create awesome
            <br />
            stuff forCandies
          </h1>
          <a
            href="mailto:work@forcandies.com?subject=I%20have%20candies%20for%20you&body=Hi%20guys,%20I%20want%20something%20from%20you..."
            className="Button"
          >
            contact us
          </a>
        </div>
      </div>

      <div className="Container">
        <a href="https://fingy.app/" target="_blank" className="Card Card--phone" data-project="fingy" rel="noreferrer">
          <div className="Card__screen">
            <img
              src="/assets/screens/fingy.png"
              srcSet="/assets/screens/fingy@2x.png 2x, /assets/screens/fingy@3x.png 3x"
              alt="Fingy"
            />
          </div>
          <div className="Card__content">
            <h2>Fingy</h2>
            <p>
              Practice for climbing with Fingy. Use vocal guidance, go through exercises made by well skilled climbers,
              follow your progress and strengthen your grip.
            </p>
            <ul className="Card__keywords">
              <li>Idea</li>
              <li>Design</li>
              <li>iOS</li>
              <li>Web</li>
            </ul>
          </div>
        </a>
        <a
          href="https://itunes.apple.com/cz/app/sv%C3%A1tkoid/id1175565318?mt=8"
          target="_blank"
          className="Card Card--phone"
          data-project="svatkoid"
          rel="noreferrer"
        >
          <div className="Card__screen">
            <img
              src="/assets/screens/svatkoid.png"
              srcSet="/assets/screens/svatkoid@2x.png 2x, /assets/screens/svatkoid@3x.png 3x"
              alt="Svátkoid"
            />
          </div>
          <div className="Card__content">
            <h2>Svátkoid</h2>
            <p>
              I am Svátkoid, mobile application for Czech republic. I will notify you about your friends namedays. If
              you want, I will be here for you every day. I am looking forward to know all your friends.
            </p>
            <ul className="Card__keywords">
              <li>Idea</li>
              <li>Design</li>
              <li>iOS</li>
            </ul>
          </div>
        </a>
        <a
          href="https://idecin.cz"
          target="_blank"
          className="Card Card--desktop"
          data-project="idecin"
          rel="noreferrer"
        >
          <div className="Card__content">
            <h2>iDecin.cz</h2>
            <p>
              Redesigned visitors portal for our hometown Decin. Rebuilded in all aspects - started from audit and
              analysis. New content strategy was set and fresh design was made. All that is powered by new handy CMS.
            </p>
            <ul className="Card__keywords">
              <li>Analysis</li>
              <li>Design</li>
              <li>Web</li>
            </ul>
          </div>
          <div className="Card__screen">
            <img
              src="/assets/screens/idecin.png"
              srcSet="/assets/screens/idecin@2x.png 2x, /assets/screens/idecin@3x.png 3x"
              alt="iDecin.cz"
            />
          </div>
        </a>
        <a
          href="https://iconsfeed.com"
          target="_blank"
          className="Card Card--desktop"
          data-project="iconsfeed"
          rel="noreferrer"
        >
          <div className="Card__content">
            <h2>Iconsfeed</h2>
            <p>
              iOS icon gallery that only accepts icons from real applications. Anyone can submit an application from the
              iOS App Store to have the app’s icon queued for approval. If accepted it’ll be added to Iconsfeed with
              many other beautiful examples.
            </p>
            <ul className="Card__keywords">
              <li>Idea</li>
              <li>Design</li>
              <li>Web</li>
            </ul>
          </div>
          <div className="Card__screen">
            <img
              src="/assets/screens/iconsfeed.png"
              srcSet="/assets/screens/iconsfeed@2x.png 2x, /assets/screens/iconsfeed@3x.png 3x"
              alt="Iconsfeed"
            />
          </div>
        </a>
      </div>

      <div className="Contact">
        <a
          href="mailto:work@forcandies.com?subject=I%20have%20candies%20for%20you&body=Hi%20guys,%20I%20want%20something%20from%20you..."
          className="Button"
        >
          contact us
        </a>
      </div>

      <div className="Story">
        <div className="Container">
          <h2>Our story</h2>
          <div className="Story__timeline">
            <ul>
              <li>
                <span className="Story__timeline__event Story__timeline__event--pink">
                  Fingy app released to{' '}
                  <a href="https://fingy.app" target="_blank" rel="noreferrer">
                    Apple App Store
                  </a>
                </span>
                <span className="Story__timeline__description">
                  The best app for all climbers who train on hangboards. Fingy provides great collection of training in
                  your pocket.
                </span>
                <span className="Story__timeline__date">April 2020</span>
              </li>
              <li>
                <span className="Story__timeline__event Story__timeline__event--pink">
                  <a href="https://idecin.cz" target="_blank" rel="noreferrer">
                    iDecin.cz
                  </a>{' '}
                  website published
                </span>
                <span className="Story__timeline__description">
                  Rebuilded multilanguage portal for Decin city visitors and tourists
                </span>
                <span className="Story__timeline__date">May 2019</span>
              </li>
              <li>
                <span className="Story__timeline__event">
                  Nesnězeno iOS app released to{' '}
                  <a
                    href="https://itunes.apple.com/us/app/nesn%C4%9Bzeno-cz/id1460586515?l=cs&ls=1&mt=8"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Apple App Store
                  </a>
                </span>
                <span className="Story__timeline__date">April 2019</span>
              </li>
              <li>
                <span className="Story__timeline__event Story__timeline__event--pink">
                  <a href="https://googlephotos.forcandies.com" target="_blank" rel="noreferrer">
                    Google Photos for Desktop
                  </a>{' '}
                  released
                </span>
                <span className="Story__timeline__description">
                  Open source desktop application for Mac. We made it as side-project, but there were awesome buzz
                  around it.
                </span>
                <span className="Story__timeline__date">May 2018</span>
              </li>
              <li>
                <span className="Story__timeline__event">
                  We won{' '}
                  <a
                    href="https://www.kr-ustecky.cz/aplikace-na-chytre-parkovani-anebo-platforma-pro-zapojeni-obcanu-mesta-do-tvorby-smart-city-programatori-pres-vikend-vylepsovali-decin/d-1722382"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Děčín City Hackathon
                  </a>{' '}
                  (48 hours, 12 teams)
                </span>
                <span className="Story__timeline__date">March 2018</span>
              </li>
              <li>
                <span className="Story__timeline__event">Cooperation with Ignum started</span>
                <span className="Story__timeline__date">December 2017</span>
              </li>
              <li>
                <span className="Story__timeline__event Story__timeline__event--pink">
                  Svátkoid app released to{' '}
                  <a
                    href="https://itunes.apple.com/cz/app/sv%C3%A1tkoid/id1175565318?mt=8"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Apple App Store
                  </a>
                </span>
                <span className="Story__timeline__description">
                  In only 4 months we had over 1000 downloads in Appstore (which is great on Czech market). Our Appstore
                  impressions/product page view/install conversion is approximately 10/1/1.
                </span>
                <span className="Story__timeline__date">March 2017</span>
              </li>
              <li>
                <span className="Story__timeline__event">
                  Pixbuf iOS app released to{' '}
                  <a
                    className="through"
                    href="https://itunes.apple.com/app/pixbuf/id1084322171?&mt=8"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Apple App Store
                  </a>
                </span>
                <span className="Story__timeline__date">July 2016</span>
              </li>
              <li>
                <span className="Story__timeline__event">Cooperation with Pixbuf started</span>
                <span className="Story__timeline__date">December 2015</span>
              </li>
              <li>
                <span className="Story__timeline__event Story__timeline__event--pink">
                  We are now friends with 2FRESH agency.
                </span>
                <span className="Story__timeline__description">
                  Thanks to them, we have worked for big clients like:
                  <br />
                  O2, Avast, KB, Globus, Kooperativa etc.
                </span>
                <span className="Story__timeline__date">August 2015</span>
              </li>
              <li>
                <span className="Story__timeline__event">Cooperation with local University started</span>
                <span className="Story__timeline__date">March 2015</span>
              </li>
              <li>
                <span className="Story__timeline__event">
                  iOS app for our hometown Děčín was released to{' '}
                  <a
                    href="https://itunes.apple.com/us/app/decin-oficialni-aplikace/id967987981?l=cs&ls=1&mt=8"
                    className="through"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Apple App Store
                  </a>
                  .
                </span>
                <span className="Story__timeline__date">February 2015</span>
              </li>
              <li>
                <span className="Story__timeline__event Story__timeline__event--pink">
                  Iconsfeed &ndash; iOS icons gallery created
                </span>
                <span className="Story__timeline__description">
                  iOS icon gallery that only accepts icons from real applications. Anyone can submit an application from
                  the iOS App Store to have the app’s icon queued for approval. If accepted it’ll be added to{' '}
                  <a href="http://www.iconsfeed.com" target="_blank" rel="noreferrer">
                    Iconsfeed
                  </a>
                  with many other beautiful examples.
                </span>
                <span className="Story__timeline__date">September 2014</span>
              </li>
              <li>
                <span className="Story__timeline__event">
                  <a href="https://davidherzina.com/" target="_blank" rel="noreferrer">
                    David
                  </a>{' '}
                  met{' '}
                  <a href="https://danielmaslo.com/" target="_blank" rel="noreferrer">
                    Dan
                  </a>
                  .
                </span>
                <span className="Story__timeline__date">February 2013</span>
              </li>
            </ul>
            <div className="Story__timeline__line">&nbsp;</div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Site;
